demo = {

  initContactUsMap: function() {
    var myLatlng = new google.maps.LatLng(44.433530, 26.093928);
    var mapOptions = {
      zoom: 14,
      center: myLatlng,
      styles: [{
        "featureType": "water",
        "stylers": [{
          "saturation": 43
        }, {
          "lightness": -11
        }, {
          "hue": "#0088ff"
        }]
      }, {
        "featureType": "road",
        "elementType": "geometry.fill",
        "stylers": [{
          "hue": "#ff0000"
        }, {
          "saturation": -100
        }, {
          "lightness": 99
        }]
      }, {
        "featureType": "road",
        "elementType": "geometry.stroke",
        "stylers": [{
          "color": "#808080"
        }, {
          "lightness": 54
        }]
      }, {
        "featureType": "landscape.man_made",
        "elementType": "geometry.fill",
        "stylers": [{
          "color": "#ece2d9"
        }]
      }, {
        "featureType": "poi.park",
        "elementType": "geometry.fill",
        "stylers": [{
          "color": "#ccdca1"
        }]
      }, {
        "featureType": "road",
        "elementType": "labels.text.fill",
        "stylers": [{
          "color": "#767676"
        }]
      }, {
        "featureType": "road",
        "elementType": "labels.text.stroke",
        "stylers": [{
          "color": "#ffffff"
        }]
      }, {
        "featureType": "poi",
        "stylers": [{
          "visibility": "off"
        }]
      }, {
        "featureType": "landscape.natural",
        "elementType": "geometry.fill",
        "stylers": [{
          "visibility": "on"
        }, {
          "color": "#b8cb93"
        }]
      }, {
        "featureType": "poi.park",
        "stylers": [{
          "visibility": "on"
        }]
      }, {
        "featureType": "poi.sports_complex",
        "stylers": [{
          "visibility": "on"
        }]
      }, {
        "featureType": "poi.medical",
        "stylers": [{
          "visibility": "on"
        }]
      }, {
        "featureType": "poi.business",
        "stylers": [{
          "visibility": "simplified"
        }]
      }],
      scrollwheel: false, //we disable de scroll over the map, it is a really annoing when you scroll through page
    };
    var map = new google.maps.Map(document.getElementById("contactUsMap"), mapOptions);

    var marker = new google.maps.Marker({
      position: myLatlng,
      title: "Hello World!"
    });
    marker.setMap(map);
  },

  initContactUs2Map: function() {
    var lat = 44.433530;
    var long = 26.093928;

    var centerLong = long - 0.025;

    var myLatlng = new google.maps.LatLng(lat, long);
    var centerPosition = new google.maps.LatLng(lat, centerLong);

    var mapOptions = {
      zoom: 14,
      center: centerPosition,
      styles: [{
        "featureType": "water",
        "stylers": [{
          "saturation": 43
        }, {
          "lightness": -11
        }, {
          "hue": "#0088ff"
        }]
      }, {
        "featureType": "road",
        "elementType": "geometry.fill",
        "stylers": [{
          "hue": "#ff0000"
        }, {
          "saturation": -100
        }, {
          "lightness": 99
        }]
      }, {
        "featureType": "road",
        "elementType": "geometry.stroke",
        "stylers": [{
          "color": "#808080"
        }, {
          "lightness": 54
        }]
      }, {
        "featureType": "landscape.man_made",
        "elementType": "geometry.fill",
        "stylers": [{
          "color": "#ece2d9"
        }]
      }, {
        "featureType": "poi.park",
        "elementType": "geometry.fill",
        "stylers": [{
          "color": "#ccdca1"
        }]
      }, {
        "featureType": "road",
        "elementType": "labels.text.fill",
        "stylers": [{
          "color": "#767676"
        }]
      }, {
        "featureType": "road",
        "elementType": "labels.text.stroke",
        "stylers": [{
          "color": "#ffffff"
        }]
      }, {
        "featureType": "poi",
        "stylers": [{
          "visibility": "off"
        }]
      }, {
        "featureType": "landscape.natural",
        "elementType": "geometry.fill",
        "stylers": [{
          "visibility": "on"
        }, {
          "color": "#b8cb93"
        }]
      }, {
        "featureType": "poi.park",
        "stylers": [{
          "visibility": "on"
        }]
      }, {
        "featureType": "poi.sports_complex",
        "stylers": [{
          "visibility": "on"
        }]
      }, {
        "featureType": "poi.medical",
        "stylers": [{
          "visibility": "on"
        }]
      }, {
        "featureType": "poi.business",
        "stylers": [{
          "visibility": "simplified"
        }]
      }],
      scrollwheel: false, //we disable de scroll over the map, it is a really annoing when you scroll through page
    };
    var map = new google.maps.Map(document.getElementById("contactUs2Map"), mapOptions);

    var marker = new google.maps.Marker({
      position: myLatlng,
      title: "Hello World!"
    });
    marker.setMap(map);
  },

  presentationAnimations: function() {
    $(function() {

      var $window = $(window),
        isTouch = Modernizr.touch;

      if (isTouch) {
        $('.add-animation').addClass('animated');
      }

      $window.on('scroll', revealAnimation);

      function revealAnimation() {
        // Showed...
        $(".add-animation:not(.animated)").each(function() {
          var $this = $(this),
            offsetTop = $this.offset().top,
            scrolled = $window.scrollTop(),
            win_height_padded = $window.height();
          if (scrolled + win_height_padded > offsetTop) {
            $this.addClass('animated');
          }
        });
        // Hidden...
        $(".add-animation.animated").each(function(index) {
          var $this = $(this),
            offsetTop = $this.offset().top;
          scrolled = $window.scrollTop(),
            windowHeight = $window.height();

          win_height_padded = windowHeight * 0.8;
          if (scrolled + win_height_padded < offsetTop) {
            $(this).removeClass('animated')
          }
        });
      }

      revealAnimation();
    });

  },

  initDateTimePicker: function() {
    $('.datetimepicker').datetimepicker({
      icons: {
        time: "fa fa-clock-o",
        date: "fa fa-calendar",
        up: "fa fa-chevron-up",
        down: "fa fa-chevron-down",
        previous: 'fa fa-chevron-left',
        next: 'fa fa-chevron-right',
        today: 'fa fa-screenshot',
        clear: 'fa fa-trash',
        close: 'fa fa-remove'
      }
    });
  },


  initPickColor: function() {
    $('.pick-class-label').click(function() {
      var new_class = $(this).attr('new-class');
      var old_class = $('#display-buttons').attr('data-class');
      var display_div = $('#display-buttons');
      if (display_div.length) {
        var display_buttons = display_div.find('.btn');
        display_buttons.removeClass(old_class);
        display_buttons.addClass(new_class);
        display_div.attr('data-class', new_class);
      }
    });
  },

  initMaterialWizard: function() {
    // Code for the Validator
    var $validator = $('.card-wizard form').validate({
      rules: {
        firstname: {
          required: true,
          minlength: 3
        },
        lastname: {
          required: true,
          minlength: 3
        },
        email: {
          required: true,
          minlength: 3,
        }
      },

      highlight: function(element) {
        $(element).closest('.form-group').removeClass('has-success').addClass('has-danger');
      },
      success: function(element) {
        $(element).closest('.form-group').removeClass('has-danger').addClass('has-success');
      },
      errorPlacement: function(error, element) {
        $(element).append(error);
      }
    });



    // Wizard Initialization
    $('.card-wizard').bootstrapWizard({
      'tabClass': 'nav nav-pills',
      'nextSelector': '.btn-next',
      'previousSelector': '.btn-previous',

      onNext: function(tab, navigation, index) {
        var $valid = $('.card-wizard form').valid();
        if (!$valid) {
          $validator.focusInvalid();
          return false;
        }
      },

      onInit: function(tab, navigation, index) {
        //check number of tabs and fill the entire row
        var $total = navigation.find('li').length;
        var $wizard = navigation.closest('.card-wizard');

        $first_li = navigation.find('li:first-child a').html();
        $moving_div = $('<div class="moving-tab">' + $first_li + '</div>');
        $('.card-wizard .wizard-navigation').append($moving_div);

        refreshAnimation($wizard, index);

        $('.moving-tab').css('transition', 'transform 0s');
      },

      onTabClick: function(tab, navigation, index) {
        var $valid = $('.card-wizard form').valid();

        if (!$valid) {
          return false;
        } else {
          return true;
        }
      },

      onTabShow: function(tab, navigation, index) {
        var $total = navigation.find('li').length;
        var $current = index + 1;

        var $wizard = navigation.closest('.card-wizard');

        // If it's the last tab then hide the last button and show the finish instead
        if ($current >= $total) {
          $($wizard).find('.btn-next').hide();
          $($wizard).find('.btn-finish').show();
        } else {
          $($wizard).find('.btn-next').show();
          $($wizard).find('.btn-finish').hide();
        }

        button_text = navigation.find('li:nth-child(' + $current + ') a').html();

        setTimeout(function() {
          $('.moving-tab').text(button_text);
        }, 150);

        var checkbox = $('.footer-checkbox');

        if (!index == 0) {
          $(checkbox).css({
            'opacity': '0',
            'visibility': 'hidden',
            'position': 'absolute'
          });
        } else {
          $(checkbox).css({
            'opacity': '1',
            'visibility': 'visible'
          });
        }

        refreshAnimation($wizard, index);
      }
    });


    // Prepare the preview for profile picture
    $("#wizard-picture").change(function() {
      readURL(this);
    });

    $('[data-toggle="wizard-radio"]').click(function() {
      wizard = $(this).closest('.card-wizard');
      wizard.find('[data-toggle="wizard-radio"]').removeClass('active');
      $(this).addClass('active');
      $(wizard).find('[type="radio"]').removeAttr('checked');
      $(this).find('[type="radio"]').attr('checked', 'true');
    });

    $('[data-toggle="wizard-checkbox"]').click(function() {
      if ($(this).hasClass('active')) {
        $(this).removeClass('active');
        $(this).find('[type="checkbox"]').removeAttr('checked');
      } else {
        $(this).addClass('active');
        $(this).find('[type="checkbox"]').attr('checked', 'true');
      }
    });

    $('.set-full-height').css('height', 'auto');

    //Function to show image before upload

    function readURL(input) {
      if (input.files && input.files[0]) {
        var reader = new FileReader();

        reader.onload = function(e) {
          $('#wizardPicturePreview').attr('src', e.target.result).fadeIn('slow');
        }
        reader.readAsDataURL(input.files[0]);
      }
    }

    $(window).resize(function() {
      $('.card-wizard').each(function() {
        $wizard = $(this);

        index = $wizard.bootstrapWizard('currentIndex');
        refreshAnimation($wizard, index);

        $('.moving-tab').css({
          'transition': 'transform 0s'
        });
      });
    });

    function refreshAnimation($wizard, index) {
      $total = $wizard.find('.nav li').length;
      $li_width = 100 / $total;

      total_steps = $wizard.find('.nav li').length;
      move_distance = $wizard.width() / total_steps;
      index_temp = index;
      vertical_level = 0;

      mobile_device = $(document).width() < 600 && $total > 3;

      if (mobile_device) {
        move_distance = $wizard.width() / 2;
        index_temp = index % 2;
        $li_width = 50;
      }

      $wizard.find('.nav li').css('width', $li_width + '%');

      step_width = move_distance;
      move_distance = move_distance * index_temp;

      $current = index + 1;

      if ($current == 1 || (mobile_device == true && (index % 2 == 0))) {
        move_distance -= 8;
      } else if ($current == total_steps || (mobile_device == true && (index % 2 == 1))) {
        move_distance += 8;
      }

      if (mobile_device) {
        vertical_level = parseInt(index / 2);
        vertical_level = vertical_level * 38;
      }

      $wizard.find('.moving-tab').css('width', step_width);
      $('.moving-tab').css({
        'transform': 'translate3d(' + move_distance + 'px, ' + vertical_level + 'px, 0)',
        'transition': 'all 0.5s cubic-bezier(0.29, 1.42, 0.79, 1)'

      });
    }
  },

  initCharts: function() {
    if ($('#roundedLineChart').length != 0 && $('#straightLinesChart').length != 0 && $('#colouredRoundedLineChart').length != 0 && $('#colouredBarsChart').length != 0 && $('#simpleBarChart').length != 0 && $('#multipleBarsChart').length != 0) {
      /* ----------==========    Rounded Line Chart initialization    ==========---------- */

      dataRoundedLineChart = {
        labels: ['M', 'T', 'W', 'T', 'F', 'S', 'S'],
        series: [
          [12, 17, 7, 17, 23, 18, 38]
        ]
      };

      optionsRoundedLineChart = {
        lineSmooth: Chartist.Interpolation.cardinal({
          tension: 10
        }),
        axisX: {
          showGrid: false,
        },
        low: 0,
        high: 50, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
        chartPadding: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 0
        },
        showPoint: false
      }

      var RoundedLineChart = new Chartist.Line('#roundedLineChart', dataRoundedLineChart, optionsRoundedLineChart);

      md.startAnimationForLineChart(RoundedLineChart);


      /*  **************** Straight Lines Chart - single line with points ******************** */

      dataStraightLinesChart = {
        labels: ['\'07', '\'08', '\'09', '\'10', '\'11', '\'12', '\'13', '\'14', '\'15'],
        series: [
          [10, 16, 8, 13, 20, 15, 20, 34, 30]
        ]
      };

      optionsStraightLinesChart = {
        lineSmooth: Chartist.Interpolation.cardinal({
          tension: 0
        }),
        low: 0,
        high: 50, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
        chartPadding: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 0
        },
        classNames: {
          point: 'ct-point ct-white',
          line: 'ct-line ct-white'
        }
      }

      var straightLinesChart = new Chartist.Line('#straightLinesChart', dataStraightLinesChart, optionsStraightLinesChart);

      md.startAnimationForLineChart(straightLinesChart);


      /*  **************** Coloured Rounded Line Chart - Line Chart ******************** */


      dataColouredRoundedLineChart = {
        labels: ['\'06', '\'07', '\'08', '\'09', '\'10', '\'11', '\'12', '\'13', '\'14', '\'15'],
        series: [
          [287, 480, 290, 554, 690, 690, 500, 752, 650, 900, 944]
        ]
      };

      optionsColouredRoundedLineChart = {
        lineSmooth: Chartist.Interpolation.cardinal({
          tension: 10
        }),
        axisY: {
          showGrid: true,
          offset: 40
        },
        axisX: {
          showGrid: false,
        },
        low: 0,
        high: 1000,
        showPoint: true,
        height: '300px'
      };


      var colouredRoundedLineChart = new Chartist.Line('#colouredRoundedLineChart', dataColouredRoundedLineChart, optionsColouredRoundedLineChart);

      md.startAnimationForLineChart(colouredRoundedLineChart);


      /*  **************** Coloured Rounded Line Chart - Line Chart ******************** */


      dataColouredBarsChart = {
        labels: ['\'06', '\'07', '\'08', '\'09', '\'10', '\'11', '\'12', '\'13', '\'14', '\'15'],
        series: [
          [287, 385, 490, 554, 586, 698, 695, 752, 788, 846, 944],
          [67, 152, 143, 287, 335, 435, 437, 539, 542, 544, 647],
          [23, 113, 67, 190, 239, 307, 308, 439, 410, 410, 509]
        ]
      };

      optionsColouredBarsChart = {
        lineSmooth: Chartist.Interpolation.cardinal({
          tension: 10
        }),
        axisY: {
          showGrid: true,
          offset: 40
        },
        axisX: {
          showGrid: false,
        },
        low: 0,
        high: 1000,
        showPoint: true,
        height: '300px'
      };


      var colouredBarsChart = new Chartist.Line('#colouredBarsChart', dataColouredBarsChart, optionsColouredBarsChart);

      md.startAnimationForLineChart(colouredBarsChart);



      /*  **************** Public Preferences - Pie Chart ******************** */

      var dataPreferences = {
        labels: ['62%', '32%', '6%'],
        series: [62, 32, 6]
      };

      var optionsPreferences = {
        height: '230px'
      };

      Chartist.Pie('#chartPreferences', dataPreferences, optionsPreferences);

      /*  **************** Simple Bar Chart - barchart ******************** */

      var dataSimpleBarChart = {
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        series: [
          [542, 443, 320, 780, 553, 453, 326, 434, 568, 610, 756, 895]
        ]
      };

      var optionsSimpleBarChart = {
        seriesBarDistance: 10,
        axisX: {
          showGrid: false
        }
      };

      var responsiveOptionsSimpleBarChart = [
        ['screen and (max-width: 640px)', {
          seriesBarDistance: 5,
          axisX: {
            labelInterpolationFnc: function(value) {
              return value[0];
            }
          }
        }]
      ];

      var simpleBarChart = Chartist.Bar('#simpleBarChart', dataSimpleBarChart, optionsSimpleBarChart, responsiveOptionsSimpleBarChart);

      //start animation for the Emails Subscription Chart
      md.startAnimationForBarChart(simpleBarChart);


      var dataMultipleBarsChart = {
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        series: [
          [542, 443, 320, 780, 553, 453, 326, 434, 568, 610, 756, 895],
          [412, 243, 280, 580, 453, 353, 300, 364, 368, 410, 636, 695]
        ]
      };

      var optionsMultipleBarsChart = {
        seriesBarDistance: 10,
        axisX: {
          showGrid: false
        },
        height: '300px'
      };

      var responsiveOptionsMultipleBarsChart = [
        ['screen and (max-width: 640px)', {
          seriesBarDistance: 5,
          axisX: {
            labelInterpolationFnc: function(value) {
              return value[0];
            }
          }
        }]
      ];

      var multipleBarsChart = Chartist.Bar('#multipleBarsChart', dataMultipleBarsChart, optionsMultipleBarsChart, responsiveOptionsMultipleBarsChart);

      //start animation for the Emails Subscription Chart
      md.startAnimationForBarChart(multipleBarsChart);
    }

  },

  showSwal: function(type) {
    if (type == 'basic') {
      swal({
        title: "Here's a message!",
        buttonsStyling: false,
        confirmButtonClass: "btn btn-success"
      }).catch(swal.noop)

    } else if (type == 'title-and-text') {
      swal({
        title: "Here's a message!",
        text: "It's pretty, isn't it?",
        buttonsStyling: false,
        confirmButtonClass: "btn btn-info"
      }).catch(swal.noop)

    } else if (type == 'success-message') {
      swal({
        title: "Good job!",
        text: "You clicked the button!",
        buttonsStyling: false,
        confirmButtonClass: "btn btn-success",
        type: "success"
      }).catch(swal.noop)

    } else if (type == 'warning-message-and-confirmation') {
      swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        type: 'warning',
        showCancelButton: true,
        confirmButtonClass: 'btn btn-success',
        cancelButtonClass: 'btn btn-danger',
        confirmButtonText: 'Yes, delete it!',
        buttonsStyling: false
      }).then(function() {
        swal({
          title: 'Deleted!',
          text: 'Your file has been deleted.',
          type: 'success',
          confirmButtonClass: "btn btn-success",
          buttonsStyling: false
        })
      }).catch(swal.noop)
    } else if (type == 'warning-message-and-cancel') {
      swal({
        title: 'Are you sure?',
        text: 'You will not be able to recover this imaginary file!',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it',
        confirmButtonClass: "btn btn-success",
        cancelButtonClass: "btn btn-danger",
        buttonsStyling: false
      }).then(function() {
        swal({
          title: 'Deleted!',
          text: 'Your imaginary file has been deleted.',
          type: 'success',
          confirmButtonClass: "btn btn-success",
          buttonsStyling: false
        }).catch(swal.noop)
      }, function(dismiss) {
        // dismiss can be 'overlay', 'cancel', 'close', 'esc', 'timer'
        if (dismiss === 'cancel') {
          swal({
            title: 'Cancelled',
            text: 'Your imaginary file is safe :)',
            type: 'error',
            confirmButtonClass: "btn btn-info",
            buttonsStyling: false
          }).catch(swal.noop)
        }
      })

    } else if (type == 'custom-html') {
      swal({
        title: 'HTML example',
        buttonsStyling: false,
        confirmButtonClass: "btn btn-success",
        html: 'You can use <b>bold text</b>, ' +
          '<a href="http://github.com">links</a> ' +
          'and other HTML tags'
      }).catch(swal.noop)

    } else if (type == 'auto-close') {
      swal({
        title: "Auto close alert!",
        text: "I will close in 2 seconds.",
        timer: 2000,
        showConfirmButton: false
      }).catch(swal.noop)
    } else if (type == 'input-field') {
      swal({
        title: 'Input something',
        html: '<div class="form-group">' +
          '<input id="input-field" type="text" class="form-control" />' +
          '</div>',
        showCancelButton: true,
        confirmButtonClass: 'btn btn-success',
        cancelButtonClass: 'btn btn-danger',
        buttonsStyling: false
      }).then(function(result) {
        swal({
          type: 'success',
          html: 'You entered: <strong>' +
            $('#input-field').val() +
            '</strong>',
          confirmButtonClass: 'btn btn-success',
          buttonsStyling: false

        })
      }).catch(swal.noop)
    }
  },

  initGoogleMaps: function() {
    var myLatlng = new google.maps.LatLng(40.748817, -73.985428);
    var mapOptions = {
      zoom: 13,
      center: myLatlng,
      scrollwheel: false, //we disable de scroll over the map, it is a really annoing when you scroll through page
      styles: [{
        "featureType": "water",
        "stylers": [{
          "saturation": 43
        }, {
          "lightness": -11
        }, {
          "hue": "#0088ff"
        }]
      }, {
        "featureType": "road",
        "elementType": "geometry.fill",
        "stylers": [{
          "hue": "#ff0000"
        }, {
          "saturation": -100
        }, {
          "lightness": 99
        }]
      }, {
        "featureType": "road",
        "elementType": "geometry.stroke",
        "stylers": [{
          "color": "#808080"
        }, {
          "lightness": 54
        }]
      }, {
        "featureType": "landscape.man_made",
        "elementType": "geometry.fill",
        "stylers": [{
          "color": "#ece2d9"
        }]
      }, {
        "featureType": "poi.park",
        "elementType": "geometry.fill",
        "stylers": [{
          "color": "#ccdca1"
        }]
      }, {
        "featureType": "road",
        "elementType": "labels.text.fill",
        "stylers": [{
          "color": "#767676"
        }]
      }, {
        "featureType": "road",
        "elementType": "labels.text.stroke",
        "stylers": [{
          "color": "#ffffff"
        }]
      }, {
        "featureType": "poi",
        "stylers": [{
          "visibility": "off"
        }]
      }, {
        "featureType": "landscape.natural",
        "elementType": "geometry.fill",
        "stylers": [{
          "visibility": "on"
        }, {
          "color": "#b8cb93"
        }]
      }, {
        "featureType": "poi.park",
        "stylers": [{
          "visibility": "on"
        }]
      }, {
        "featureType": "poi.sports_complex",
        "stylers": [{
          "visibility": "on"
        }]
      }, {
        "featureType": "poi.medical",
        "stylers": [{
          "visibility": "on"
        }]
      }, {
        "featureType": "poi.business",
        "stylers": [{
          "visibility": "simplified"
        }]
      }]

    }
    var map = new google.maps.Map(document.getElementById("map"), mapOptions);

    var marker = new google.maps.Marker({
      position: myLatlng,
      title: "Hello World!"
    });

    // To add the marker to the map, call setMap();
    marker.setMap(map);
  },

  initParkingGoogleMaps(){
    console.log("initParkingGoogleMaps");
    
    // // Regular Map
    var myLatlng = new google.maps.LatLng(25.3463, 55.4209);
    var mapOptions = {
      zoom: 8,
      center: myLatlng,
      scrollwheel: true, //we disable de scroll over the map, it is a really annoing when you scroll through page
    }

    map = new google.maps.Map(document.getElementById("regularMap"), mapOptions);

      // function getLocation() {
      //   if (navigator.geolocation) {
      //     navigator.geolocation.getCurrentPosition(showPosition);
      //   } else { 
      //     x.innerHTML = "Geolocation is not supported by this browser.";
      //   }
      // }

      // function showPosition(position) {
      //   console.log(position.coords.latitude);
      //   console.log(position.coords.longitude);
      //   local_location = new google.maps.LatLng(position.coords.latitude,position.coords.longitude);
      //   map.setCenter(new google.maps.LatLng(position.coords.latitude, position.coords.longitude));

      // }
      // getLocation();

      google.maps.event.addListener(map, 'click', function(event) {
         placeMarker(event.latLng);
      });

      function placeMarker(location) {
        $.each(markers, function( index, value ) {
          markers[index].setMap(null);
        });


          var marker = new google.maps.Marker({
              position: location, 
              map: map
          });
          markers.push(marker);

          console.log(location);
          $("#parking_latitude").val(location.lat());
          $("#parking_longitude").val(location.lng());

           var geocoder = new google.maps.Geocoder;

          geocoder.geocode({'location': location}, function(results, status) {
            if (status === 'OK') {
              if (results[0]) {
                $("#parking_location").val(results[0].formatted_address);
              } else {
                window.alert('No results found');
              }
            } else {
              window.alert('Geocoder failed due to: ' + status);
            }
          });




      }





  },
  initSmallGoogleMaps: function() {






    // // Regular Map
    console.log("check zoomout")
    var myLatlng = new google.maps.LatLng(25.3463, 55.4209);
    var mapOptions = {
      zoom: 8,
      center: myLatlng,
      scrollwheel: true, //we disable de scroll over the map, it is a really annoing when you scroll through page
    }
    console.log("check zoomin")
    var map = new google.maps.Map(document.getElementById("regularMap"), mapOptions);

      function getLocation() {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(showPosition);
        } else { 
          x.innerHTML = "Geolocation is not supported by this browser.";
        }
      }

      function showPosition(position) {
        console.log(position.coords.latitude);
        console.log(position.coords.longitude);
        local_location = new google.maps.LatLng(position.coords.latitude,position.coords.longitude);
        map.setCenter(new google.maps.LatLng(position.coords.latitude, position.coords.longitude));

      }
      getLocation();



    for (var i = 0, length = json_unreserved.length; i < length; i++) {
      var activeInfoWindow;
      var infowindow = new google.maps.InfoWindow();
      var icon = {
        url: scooter_unreservedicon, // url
        scaledSize: new google.maps.Size(30, 30)
      };
      
      var data = json_unreserved[i]["scooter"]
      var zone = json_unreserved[i]["zone"]
      LatLng = {lat: Number(data.latitude), lng: Number(data.longitude)};
      var badge_color
      if (data.current_mode == "free"){badge_color = "success"}else if(data.current_mode == "ride"){badge_color = "info"}else{badge_color = "danger"};
      var content = '<div class=row><div class="col-md-12"><ul class="p-0 mb-0" style = "list-style-type: none;"><li class="pb-1"><b>Scooter no: </b>'+ data.scooter_no.toString() +'</li><li class="pb-1"><b>Battery: </b>'+data.power_percent.toString() +'</li><li class="pb-1">'+  +'</li>'
            +
            '</div></div>';
      var marker = new google.maps.Marker({
        position: LatLng,
        map: map,
        icon: icon,
        title: "Scooter no: " + data.scooter_no.toString(),
        animation: google.maps.Animation.DROP
      });
      google.maps.event.addListener(marker,'click', (function(marker,content,infowindow){
      return function() {
          if (activeInfoWindow) { activeInfoWindow.close();}
          infowindow.setContent(content);
          infowindow.open(map,marker);
          activeInfoWindow = infowindow;
      };
      })(marker,content,infowindow));
      marker.setMap(map);
    }

    for (var i = 0, length = json_unreserved_b2b.length; i < length; i++) {
      var activeInfoWindow;
      var infowindow = new google.maps.InfoWindow();
      var icon = {
        url: scooter_unreserved_icon_b2b, // url
        scaledSize: new google.maps.Size(30, 30)
    };
      var data = json_unreserved_b2b[i]["scooter"]
      var zone = json_unreserved_b2b[i]["zone"]
      LatLng = {lat: Number(data.latitude), lng: Number(data.longitude)};
      var badge_color
      if (data.current_mode == "free"){badge_color = "success"}else if(data.current_mode == "ride"){badge_color = "info"}else{badge_color = "danger"};

      var content = '<div class=row><div class="col-md-12"><ul class="p-0 mb-0" style = "list-style-type: none;"><li class="pb-1"><b>Scooter no: </b>'+ data.scooter_no.toString() +'</li><li class="pb-1"><b>Battery: </b>'+data.power_percent.toString()+'</li><li class="pb-1"></li><li class="pb-1">'+ data.bussiness_name.toString() +'</li>'
            +
            '</div></div>';
      var marker = new google.maps.Marker({
        position: LatLng,
        map: map,
        icon: icon,
        title: "Scooter no: " + data.scooter_no.toString(),
        animation: google.maps.Animation.DROP
      });
      google.maps.event.addListener(marker,'click', (function(marker,content,infowindow){
      return function() {
          if (activeInfoWindow) { activeInfoWindow.close();}
          infowindow.setContent(content);
          infowindow.open(map,marker);
          activeInfoWindow = infowindow;
      };
      })(marker,content,infowindow));
      marker.setMap(map);
    }


    for (var i = 0, length = json_scooters_charging_scooters.length; i < length; i++) {
      var activeInfoWindow;
      var infowindow = new google.maps.InfoWindow();
      var data = json_scooters_charging_scooters[i]["scooter"]
      var zone = json_scooters_charging_scooters[i]["zone"]
      LatLng = {lat: Number(data.latitude), lng: Number(data.longitude)};
      var badge_color
      if (data.current_mode == "free"){badge_color = "success"}else if(data.current_mode == "ride"){badge_color = "info"}else{badge_color = "danger"};
      var content = '<div class=row><div class="col-md-12"><ul class="p-0 mb-0" style = "list-style-type: none;"><li class="pb-1"><b>Scooter no: </b>'+ data.scooter_no.toString() +'</li><li class="pb-1"><b>Battery: </b>'+ data.power_percent.toString() +'</li><li class="pb-1">'+ zone.name.toString() +'</li></ul>'
            +
            '</div></div>';
      var marker = new google.maps.Marker({
        position: LatLng,
        map: map,
        icon: charging_scooter_icon,
        title: "Scooter no: " + data.scooter_no.toString(),
        animation: google.maps.Animation.DROP
      });
      google.maps.event.addListener(marker,'click', (function(marker,content,infowindow){
      return function() {
          if (activeInfoWindow) { activeInfoWindow.close();}
          infowindow.setContent(content);
          infowindow.open(map,marker);
          activeInfoWindow = infowindow;
      };
      })(marker,content,infowindow));
      marker.setMap(map);
    }



    for (var i = 0, length = json_scooters_low_battery_scooters.length; i < length; i++) {
      var activeInfoWindow;
      var infowindow = new google.maps.InfoWindow();
      var icon = {
        url: low_battery_icon, // url
        scaledSize: new google.maps.Size(30, 30)
      };
      var data = json_scooters_low_battery_scooters[i]["scooter"]
      var zone = json_scooters_low_battery_scooters[i]["zone"]
      LatLng = {lat: Number(data.latitude), lng: Number(data.longitude)};
      var badge_color
      if (data.current_mode == "free"){badge_color = "success"}else if(data.current_mode == "ride"){badge_color = "info"}else{badge_color = "danger"};
      var content = '<div class=row><div class="col-md-12"><ul class="p-0 mb-0" style = "list-style-type: none;"><li class="pb-1"><b>Scooter no: </b>'+ data.scooter_no.toString() +'</li><li class="pb-1"><b>Battery: </b>'+ data.power_percent.toString() +'</li><li class="pb-1">'+  +'</li></ul>'
            +
            '</div></div>';
      var marker = new google.maps.Marker({
        position: LatLng,
        map: map,
        icon: icon,
        title: "Scooter no: " + data.scooter_no.toString(),
        animation: google.maps.Animation.DROP
      });
      google.maps.event.addListener(marker,'click', (function(marker,content,infowindow){
      return function() {
          if (activeInfoWindow) { activeInfoWindow.close();}
          infowindow.setContent(content);
          infowindow.open(map,marker);
          activeInfoWindow = infowindow;
      };
      })(marker,content,infowindow));
      marker.setMap(map);
    }

    

    for (var i = 0, length = json_scooters_low_battery_scooters_b2b.length; i < length; i++) {
      var activeInfoWindow;
      var infowindow = new google.maps.InfoWindow();
      var icon = {
        url: low_battery_icon_b2b, // url
        scaledSize: new google.maps.Size(30, 30)
      };
      var data = json_scooters_low_battery_scooters_b2b[i]["scooter"]
      var zone = json_scooters_low_battery_scooters_b2b[i]["zone"]
      LatLng = {lat: Number(data.latitude), lng: Number(data.longitude)};
      var badge_color
      if (data.current_mode == "free"){badge_color = "success"}else if(data.current_mode == "ride"){badge_color = "info"}else{badge_color = "danger"};
      var content = '<div class=row><div class="col-md-12"><ul class="p-0 mb-0" style = "list-style-type: none;"><li class="pb-1"><b>Scooter no: </b>'+ data.scooter_no.toString() +'</li><li class="pb-1"><b>Battery: </b>'+ data.power_percent.toString() +'</li><li class="pb-1">'+ data.bussiness_name.toString() +'</li></ul>'
            +
            '</div></div>';
      var marker = new google.maps.Marker({
        position: LatLng,
        map: map,
        icon: icon,
        title: "Scooter no: " + data.scooter_no.toString(),
        animation: google.maps.Animation.DROP
      });
      google.maps.event.addListener(marker,'click', (function(marker,content,infowindow){
      return function() {
          if (activeInfoWindow) { activeInfoWindow.close();}
          infowindow.setContent(content);
          infowindow.open(map,marker);
          activeInfoWindow = infowindow;
      };
      })(marker,content,infowindow));
      marker.setMap(map);
    }

    for (var i = 0, length = json_scooters_active_scooters.length; i < length; i++) {
      var activeInfoWindow;
      var infowindow = new google.maps.InfoWindow();
      var user = json_scooters_active_scooters[i]["user"]
      var data = json_scooters_active_scooters[i]["scooter"]
      var zone = json_scooters_active_scooters[i]["zone"]
      LatLng = {lat: Number(data.latitude), lng: Number(data.longitude)};
      var icon = {
        url: active_scooter_icon, // url
        scaledSize: new google.maps.Size(30, 30)
      };
      var badge_color
      if (data.current_mode == "free"){badge_color = "success"}else if(data.current_mode == "ride"){badge_color = "info"}else{badge_color = "danger"};
      if (user.email == null)
      {
        user.email = ""
      }
      var content = '<div class=row><div class="col-md-12"><ul class="p-0 mb-0" style = "list-style-type: none;"><li class="pb-1"><b>Scooter no: </b>'+ data.scooter_no.toString() +'</li><li class="pb-1"><b>Battery: </b>'+ data.power_percent.toString() +'%</li><li class="pb-1"><b>Email: </b>'+ user.email.toString() +'</li><li class="pb-1"><b>Contact: </b>'+ user.mobile_number.toString() +'</li><li class="pb-1">'+ zone.name.toString() +'</li></ul>'
            +
            '</div></div>';
      var marker = new google.maps.Marker({
        position: LatLng,
        map: map,
        icon: icon,
        title: "Scooter no: " + data.scooter_no.toString(),
        animation: google.maps.Animation.DROP
      });
      google.maps.event.addListener(marker,'click', (function(marker,content,infowindow){
      return function() {
          if (activeInfoWindow) { activeInfoWindow.close();}
          infowindow.setContent(content);
          infowindow.open(map,marker);
          activeInfoWindow = infowindow;
      };
      })(marker,content,infowindow));
      marker.setMap(map);
    }

    for (var i = 0, length = json_scooters_active_scooters_b2b.length; i < length; i++) {
      var activeInfoWindow;
      var infowindow = new google.maps.InfoWindow();
      var user = json_scooters_active_scooters_b2b[i]["user"]
      var data = json_scooters_active_scooters_b2b[i]["scooter"]
      var zone = json_scooters_active_scooters_b2b[i]["zone"]
      LatLng = {lat: Number(data.latitude), lng: Number(data.longitude)};
      var icon = {
        url: active_scooter_icon_b2b, // url
        scaledSize: new google.maps.Size(30, 30)
      };
      var badge_color
      if (data.current_mode == "free"){badge_color = "success"}else if(data.current_mode == "ride"){badge_color = "info"}else{badge_color = "danger"};
      if (user.email == null)
      {
        user.email = ""
      }
      var content = '<div class=row><div class="col-md-12"><ul class="p-0 mb-0" style = "list-style-type: none;"><li class="pb-1"><b>Scooter no: </b>'+ data.scooter_no.toString() +'</li><li class="pb-1"><b>Battery: </b>'+ data.power_percent.toString() +'%</li><li class="pb-1"><b>Email: </b>'+ user.email.toString() +'</li><li class="pb-1"><b>Contact: </b>'+ user.mobile_number.toString() +'</li><li class="pb-1">'+ data.bussiness_name.toString() +'</li></ul>'
            +
            '</div></div>';
      var marker = new google.maps.Marker({
        position: LatLng,
        map: map,
        icon: icon,
        title: "Scooter no: " + data.scooter_no.toString(),
        animation: google.maps.Animation.DROP
      });
      google.maps.event.addListener(marker,'click', (function(marker,content,infowindow){
      return function() {
          if (activeInfoWindow) { activeInfoWindow.close();}
          infowindow.setContent(content);
          infowindow.open(map,marker);
          activeInfoWindow = infowindow;
      };
      })(marker,content,infowindow));
      marker.setMap(map);
    }



    for (var i = 0, length = json_reserved.length; i < length; i++) {
      var activeInfoWindow;
      var infowindow = new google.maps.InfoWindow();
      var data = json_reserved[i]["scooter"]
      var user = json_reserved[i]["user"]
      LatLng = {lat: Number(data.latitude), lng: Number(data.longitude)};
      var icon = {
        url: scooterreserved_icon, // url
        scaledSize: new google.maps.Size(30, 30)
      };
      var badge_color
      if (data.current_mode == "free"){badge_color = "success"}else if(data.current_mode == "ride"){badge_color = "info"}else{badge_color = "danger"};
        if (user.email == null)
        {
          user.email = ""
        }
      var content = '<div class=row><div class="col-md-12"><ul class="p-0 mb-0" style = "list-style-type: none;"><li class="pb-1"><b>Scooter no: </b>'+ data.scooter_no.toString() +'</li><li class="pb-1"><b>Battery: </b>'+ data.power_percent.toString() +'%</li><li class="pb-1"><b>Email: </b>'+ user.email.toString() +'</li><li class="pb-1"><b>Contact: </b>'+ user.mobile_number.toString() +'</li></ul>'
            +
            '</div></div>';
      var marker = new google.maps.Marker({
        position: LatLng,
        map: map,
        icon: icon,
        title: "Scooter no: " + data.scooter_no.toString(),
        animation: google.maps.Animation.DROP
      });
      google.maps.event.addListener(marker,'click', (function(marker,content,infowindow){
      return function() {
          if (activeInfoWindow) { activeInfoWindow.close();}
          infowindow.setContent(content);
          infowindow.open(map,marker);
          activeInfoWindow = infowindow;
      };
      })(marker,content,infowindow));
      marker.setMap(map);
    }

    for (var i = 0, length = json_unlock_b2b.length; i < length; i++) {
      var activeInfoWindow;
      var infowindow = new google.maps.InfoWindow();
      var data = json_unlock_b2b[i]["scooter"]
      var user = json_unlock_b2b[i]["user"]
      LatLng = {lat: Number(data.latitude), lng: Number(data.longitude)};
      var icon = {
        url: unlock_b2b_icon, // url
        scaledSize: new google.maps.Size(30, 30)
      };
      var badge_color
      if (data.current_mode == "free"){badge_color = "success"}else if(data.current_mode == "ride"){badge_color = "info"}else{badge_color = "danger"};
      var content = '<div class=row><div class="col-md-12"><ul class="p-0 mb-0" style = "list-style-type: none;"><li class="pb-1"><b>Scooter no: </b>'+ data.scooter_no.toString() +'</li><li class="pb-1"><b>Battery: </b>'+ data.power_percent.toString() +'%</li><li class="pb-1"><b>Email: </b>'+ user.email.toString() +'</li><li class="pb-1"><b>Contact: </b>'+ "" +'</li><li class="pb-1"><b>Bussiness: </b>'+ data.bussiness_name.toString() +'</li></ul>'
            +
            '</div></div>';
      var marker = new google.maps.Marker({
        position: LatLng,
        map: map,
        icon: icon,
        title: "Scooter no: " + data.scooter_no.toString(),
        animation: google.maps.Animation.DROP
      });
      google.maps.event.addListener(marker,'click', (function(marker,content,infowindow){
      return function() {
          if (activeInfoWindow) { activeInfoWindow.close();}
          infowindow.setContent(content);
          infowindow.open(map,marker);
          activeInfoWindow = infowindow;
      };
      })(marker,content,infowindow));
      marker.setMap(map);
    }


    for (var i = 0, length = json_unlock_b2c.length; i < length; i++) {
      var activeInfoWindow;
      var infowindow = new google.maps.InfoWindow();
      var data = json_unlock_b2c[i]["scooter"]
      var user = json_unlock_b2c[i]["user"]
      LatLng = {lat: Number(data.latitude), lng: Number(data.longitude)};
      var icon = {
        url: unlock_b2c_icon, // url
        scaledSize: new google.maps.Size(30, 30)
      };
      var badge_color
      if (data.current_mode == "free"){badge_color = "success"}else if(data.current_mode == "ride"){badge_color = "info"}else{badge_color = "danger"};
        if (user.email == null)
        {
          user.email = ""
        }
      var content = '<div class=row><div class="col-md-12"><ul class="p-0 mb-0" style = "list-style-type: none;"><li class="pb-1"><b>Scooter no: </b>'+ data.scooter_no.toString() +'</li><li class="pb-1"><b>Battery: </b>'+ data.power_percent.toString() +'%</li><li class="pb-1"><b>Email: </b>'+ user.email.toString() +'</li><li class="pb-1"><b>Contact: </b>'+ "" +'</li</ul>'
            +
            '</div></div>';
      var marker = new google.maps.Marker({
        position: LatLng,
        map: map,
        icon: icon,
        title: "Scooter no: " + data.scooter_no.toString(),
        animation: google.maps.Animation.DROP
      });
      google.maps.event.addListener(marker,'click', (function(marker,content,infowindow){
      return function() {
          if (activeInfoWindow) { activeInfoWindow.close();}
          infowindow.setContent(content);
          infowindow.open(map,marker);
          activeInfoWindow = infowindow;
      };
      })(marker,content,infowindow));
      marker.setMap(map);
    }
    // b2b scooters

    for (var i = 0, length = json_all_parkings.length; i < length; i++) {
      var activeInfoWindow;
      var infowindow = new google.maps.InfoWindow();
      var data = json_all_parkings[i],
      LatLng = {lat: Number(data.latitude), lng: Number(data.longitude)};
      var marker = new google.maps.Marker({
        position: LatLng,
        map: map,
        icon: parking_icon,
        title: "Parking: " + data.location.toString(),
        animation: google.maps.Animation.DROP
      });
      marker.setMap(map);
    }





// geofence area starts






    for (var i = 0, length = no_parking_geofences_json.length; i < length; i++) {
      data = no_parking_geofences_json[i];
      var infowindow = new google.maps.InfoWindow();
      var no_parking_geofences_json_geo_area = new google.maps.Polygon({
        paths: data.coordinates,
        strokeColor: '#FF0000',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#ff0000',
        fillOpacity: 0.35,
        geo_name: data.name,
        geo_pos: data.coordinates[0]
      });

      no_parking_geofences_json_geo_area.setMap(map);

    }



    for (var i = 0, length = parking_discount_geofences_json.length; i < length; i++) {
      data = parking_discount_geofences_json[i];
      var infowindow = new google.maps.InfoWindow();
      var parking_discount_geofences_geo_area = new google.maps.Polygon({
        paths: data.coordinates,
        strokeColor: '#FF0000',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#87ceeb',
        fillOpacity: 0.35,
        geo_name: data.name,
        geo_pos: data.coordinates[0]
      });

      parking_discount_geofences_geo_area.setMap(map);

    }



    for (var i = 0, length = reduced_speed_geofences_json.length; i < length; i++) {
      data = reduced_speed_geofences_json[i];
      var infowindow = new google.maps.InfoWindow();
      var reduced_speed_geofences_geo_area = new google.maps.Polygon({
        paths: data.coordinates,
        strokeColor: '#FF0000',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#FFFF00',
        fillOpacity: 0.35,
        geo_name: data.name,
        geo_pos: data.coordinates[0]
      });

      reduced_speed_geofences_geo_area.setMap(map);

    }




     for (var i = 0, length = ride_available_geofences_json.length; i < length; i++) {
      data = ride_available_geofences_json[i];
      var infowindow = new google.maps.InfoWindow();
      var ride_available_geofences_geo_area = new google.maps.Polygon({
        paths: data.coordinates,
        strokeColor: '#FF0000',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#a9a9a9',
        fillOpacity: 0.35,
        geo_name: data.name,
        geo_pos: data.coordinates[0]
      });

      ride_available_geofences_geo_area.setMap(map);

    }   

    for (var i = 0, length = police_available_geofences_json.length; i < length; i++) {
      data = police_available_geofences_json[i];
      var infowindow = new google.maps.InfoWindow();
      var police_available_geofences_geo_area = new google.maps.Polygon({
        paths: data.coordinates,
        strokeColor: '#FF0000',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#a9a9a9',
        fillOpacity: 0.35,
        geo_name: data.name,
        geo_pos: data.coordinates[0]
      });

      ride_available_geofences_geo_area.setMap(map);

    }

    for (var i = 0, length = gray_geofences_json.length; i < length; i++) {
      data = gray_geofences_json[i];
      var infowindow = new google.maps.InfoWindow();
      var gray_geofences_geo_area = new google.maps.Polygon({
        paths: data.coordinates,
        strokeColor: '#FF0000',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#a9a9a9',
        fillOpacity: 0.35,
        geo_name: data.name,
        geo_pos: data.coordinates[0]
      });

      gray_geofences_geo_area.setMap(map);

    }

    console.log(police_available_geofences_json.length)
    for (var i = 0, length = police_available_geofences_json.length; i < length; i++) {
      console.log("check")
      data = police_available_geofences_json[i];
      console.log("name",data.name);
      console.log("corddd",data.coordinates[0]);
      var infowindow = new google.maps.InfoWindow();
      var police_available_geofences_geo_area = new google.maps.Polygon({
        paths: data.coordinates,
        strokeColor: '#FF0000',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#FFA500',
        fillOpacity: 0.45,
        geo_name: data.name,
        geo_pos: data.coordinates[0]
      });

      police_available_geofences_geo_area.setMap(map);

    }

// geofence area ends

  },

  initGeofenceGoogleMaps: function() {

    var myLatlng = new google.maps.LatLng(25.3463, 55.4209);
    var mapOptions = {
      zoom: 8,
      center: myLatlng,
      scrollwheel: true, //we disable de scroll over the map, it is a really annoing when you scroll through page
    }
    var map = new google.maps.Map(document.getElementById("regularMap"), mapOptions);

    console.log("json length",json.length);

    // for (var i = 0, length = json.length; i < length; i++) {
    //   data = json[i];
    //   console.log("json data",data.name);      
    //   console.log("json coordinates",data.coordinates[0]);      
    //   var infowindow = new google.maps.InfoWindow();
    //   var geo_area = new google.maps.Polygon({
    //     paths: data.coordinates,
    //     strokeColor: '#FF0000',
    //     strokeOpacity: 0.8,
    //     strokeWeight: 2,
    //     fillColor: '#FF0000',
    //     fillOpacity: 0.35,
    //     geo_name: data.name,
    //     geo_pos: data.coordinates[0]
    //   });

    //   geo_area.setMap(map);
    //   google.maps.event.addListener(geo_area, 'click', function() {
    //     geo_area.infoWindow = new google.maps.InfoWindow();
    //     this.setOptions({fillOpacity:0.1});
    //     geo_area.infoWindow.setPosition(this.geo_pos);
    //     geo_area.infoWindow.setContent(this.geo_name);
    //     geo_area.infoWindow.open(map);
    //   });
    // }


// start new 

console.log("no_parking_geofences_json",no_parking_geofences_json)
console.log("2",parking_discount_geofences_json)
console.log(3,reduced_speed_geofences_json)
console.log(4,ride_available_geofences_json)

  for (var i = 0, length = json_all_parkings.length; i < length; i++) {
    var activeInfoWindow;
    var infowindow = new google.maps.InfoWindow();
    var data = json_all_parkings[i],
    LatLng = {lat: Number(data.latitude), lng: Number(data.longitude)};
    var marker = new google.maps.Marker({
      position: LatLng,
      map: map,
      icon: parking_icon,
      title: "Parking: " + data.location.toString(),
      animation: google.maps.Animation.DROP
    });
    marker.setMap(map);
  }

    for (var i = 0, length = no_parking_geofences_json.length; i < length; i++) {
      data = no_parking_geofences_json[i];

      var infowindow = new google.maps.InfoWindow();
      var no_parking_geofences_json_geo_area = new google.maps.Polygon({
        paths: data.coordinates,
        strokeColor: '#FF0000',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#ff0000',
        fillOpacity: 0.35,
        geo_name: data.name,
        geo_pos: data.coordinates[0]
      });

      no_parking_geofences_json_geo_area.setMap(map);

    }



    for (var i = 0, length = parking_discount_geofences_json.length; i < length; i++) {
      data = parking_discount_geofences_json[i];
      var infowindow = new google.maps.InfoWindow();
      var parking_discount_geofences_geo_area = new google.maps.Polygon({
        paths: data.coordinates,
        strokeColor: '#FF0000',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#87ceeb',
        fillOpacity: 0.35,
        geo_name: data.name,
        geo_pos: data.coordinates[0]
      });

      parking_discount_geofences_geo_area.setMap(map);

    }



    for (var i = 0, length = reduced_speed_geofences_json.length; i < length; i++) {
      data = reduced_speed_geofences_json[i];
      var infowindow = new google.maps.InfoWindow();
      var reduced_speed_geofences_geo_area = new google.maps.Polygon({
        paths: data.coordinates,
        strokeColor: '#FF0000',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#FFFF00',
        fillOpacity: 0.35,
        geo_name: data.name,
        geo_pos: data.coordinates[0]
      });

      reduced_speed_geofences_geo_area.setMap(map);

    }



    console.log("length",ride_available_geofences_json.length);
    console.log("------------------------------------------")
    console.log("length",ride_available_geofences_json);
     for (var i = 0, length = ride_available_geofences_json.length; i < length; i++) {
      data = ride_available_geofences_json[i];
      console.log("name",data.name);
      console.log("corddd",data.coordinates[0]);
      var infowindow = new google.maps.InfoWindow();
      var ride_available_geofences_geo_area = new google.maps.Polygon({
        paths: data.coordinates,
        strokeColor: '#FF0000',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#a9a9a9',
        fillOpacity: 0.35,
        geo_name: data.name,
        geo_pos: data.coordinates[0]
      });

      ride_available_geofences_geo_area.setMap(map);

    }   

    console.log("gray zone check")
    console.log(gray_geofence_json.length)
    for (var i = 0, length = gray_geofence_json.length; i < length; i++) {
      console.log("check")
      data = gray_geofence_json[i];
      console.log("name",data.name);
      console.log("corddd",data.coordinates[0]);
      var infowindow = new google.maps.InfoWindow();
      var gray_geofences_geo_area = new google.maps.Polygon({
        paths: data.coordinates,
        strokeColor: '#FF0000',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#a9a9a9',
        fillOpacity: 0.45,
        geo_name: data.name,
        geo_pos: data.coordinates[0]
      });

      gray_geofences_geo_area.setMap(map);

    }


    console.log("=======================================")
    console.log(police_available_geofences_json.length)
    for (var i = 0, length = police_available_geofences_json.length; i < length; i++) {
      console.log("check")
      data = police_available_geofences_json[i];
      console.log("name",data.name);
      console.log("corddd",data.coordinates[0]);
      var infowindow = new google.maps.InfoWindow();
      var police_available_geofences_geo_area = new google.maps.Polygon({
        paths: data.coordinates,
        strokeColor: '#FF0000',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#FFA500',
        fillOpacity: 0.45,
        geo_name: data.name,
        geo_pos: data.coordinates[0]
      });

      police_available_geofences_geo_area.setMap(map);

    }   

    console.log(restricted_geofences_json.length)
    for (var i = 0, length = restricted_geofences_json.length; i < length; i++) {
      console.log("check")
      data = restricted_geofences_json[i];
      console.log("name",data.name);
      console.log("corddd",data.coordinates[0]);
      var infowindow = new google.maps.InfoWindow();
      var restricted_geofences_geo_area = new google.maps.Polygon({
        paths: data.coordinates,
        strokeColor: '#FFA500',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#FFA500',
        fillOpacity: 0.35,
        geo_name: data.name,
        geo_pos: data.coordinates[0]
      });

      restricted_geofences_geo_area.setMap(map);

    }   
       


// start new ends

var selectedShape;
    var myLatlng = new google.maps.LatLng(25.3463, 55.4209);
//  map = new google.maps.Map(document.getElementById('regularMap'), {
//       zoom: 14,
//       center: myLatlng,
//       scrollwheel: true
//     }); 
   var polyOptions = {
    strokeWeight: 0,
    fillOpacity: 0.45,
    editable: true,
    draggable: true
  };
//   // Creates a drawing manager attached to the map that allows the user to draw
//   // markers, lines, and shapes.
   drawingManager = new google.maps.drawing.DrawingManager({
    drawingMode: google.maps.drawing.OverlayType.POLYGON,
    drawingControlOptions: {
      drawingModes: [
        google.maps.drawing.OverlayType.POLYGON
      ]
    },
    markerOptions: {
      draggable: true
    },
    polylineOptions: {
      editable: true
    },
    rectangleOptions: polyOptions,
    circleOptions: polyOptions,
    polygonOptions: polyOptions,
    map: map
  });


  google.maps.event.addListener(drawingManager, 'overlaycomplete', function(e) {
    if (e.type != google.maps.drawing.OverlayType.MARKER) {
      drawingManager.setDrawingMode(null);
      drawingManager.setOptions({
        drawingControl: false
      });

      console.log("overlayyyyyyy");
      console.log(e.overlay);
      abc = []
      abc.push(e.overlay.getPaths())
      map_variable_path = Object.keys(abc[0])[0]
      console.log(map_variable_path );
      


      array_lat_lngs(e.overlay.getPaths().Eg[0].Eg);
      var newShape = e.overlay;
      newShape.type = e.type;
      google.maps.event.addListener(newShape, 'click', function() {
        setSelection(newShape);
      });
      setSelection(newShape);
    }
  });

//   // Clear the current selection when the drawing mode is changed, or when the
//   // map is clicked.
//   // google.maps.event.addListener(drawingManager, 'drawingmode_changed', clearSelection);
//   // google.maps.event.addListener(map, 'click', clearSelection);
//   // google.maps.event.addDomListener(document.getElementById('delete_selected_zone'), 'click', deleteSelectedShape);
 
//    // array_lat_lngs(drawingManager.getPath().getArray());



// // 
var ary_lt = [];
  function array_lat_lngs(ary){
     ary_lt = [];
    for (i = 0; i < ary.length; i++) {
    // console.log(ary);
    // console.log(ary[i].lat());
        hsh={};
        hsh.lat = ary[i].lat();
        hsh.lng = ary[i].lng();
        ary_lt.push(hsh);
        console.log(ary.length);
        console.log(i);
        if(i == (ary.length - 1)){
          console.log("last element");
          console.log(ary_lt.length);
          var coordinates = document.getElementById("coordinates");
          coordinates.value = JSON.stringify(ary_lt);


        }

    }
  }

function clearSelection() {
  console.log("clearSelection");
  if (selectedShape) {
    console.log(selectedShape);
    selectedShape.setEditable(false);
    selectedShape = null;
  }
}
function setSelection(shape) {
  console.log("setSelection");
  clearSelection();
  selectedShape = shape;
  shape.setEditable(true);
  addDeleteButton(shape, 'http://i.imgur.com/RUrKV.png');
  // selectColor(shape.get('fillColor') || shape.get('strokeColor'));
}

function addDeleteButton(poly, imageUrl) {
  var path = poly.getPath();
  path["btnDeleteClickHandler"] = {};
  path["btnDeleteImageUrl"] = imageUrl;
  
  google.maps.event.addListener(poly.getPath(),'set_at',pointUpdated);
  google.maps.event.addListener(poly.getPath(),'insert_at',pointUpdated);
}

function pointUpdated(index) {
  var path = this;
  var btnDelete = getDeleteButton(path.btnDeleteImageUrl);
  
  if(btnDelete.length === 0) 
  {
    var undoimg = $("img[src$='https://maps.gstatic.com/mapfiles/undo_poly.png']");
    
    undoimg.parent().css('height', '21px !important');
    undoimg.parent().parent().append('<div style="overflow-x: hidden; overflow-y: hidden; position: absolute; width: 30px; height: 27px;top:21px;"><img src="' + path.btnDeleteImageUrl + '" class="deletePoly" style="height:auto; width:auto; position: absolute; left:0;"/></div>');
    
    // now get that button back again!
    btnDelete = getDeleteButton(path.btnDeleteImageUrl);
    btnDelete.hover(function() { $(this).css('left', '-30px'); return false;}, 
                    function() { $(this).css('left', '0px'); return false;});
    btnDelete.mousedown(function() { $(this).css('left', '-60px'); return false;});
  }
  
  // if we've already attached a handler, remove it
  if(path.btnDeleteClickHandler) 
    btnDelete.unbind('click', path.btnDeleteClickHandler);
    
  // now add a handler for removing the passed in index
  path.btnDeleteClickHandler = function() {
 
    path.removeAt(index); 
    return false;
  };
  btnDelete.click(path.btnDeleteClickHandler);
  array_lat_lngs(path.g);
  // console.log(ary_lt);
}
function getDeleteButton(imageUrl) {
  return  $("img[src$='" + imageUrl + "']");
}



  var coordinates = document.getElementById("coordinates");
  coordinates.value = JSON.stringify(ary_lt);



// ++++++
  },
  createPolygon: function() {

//     var selectedShape;
//     var myLatlng = new google.maps.LatLng(25.3463, 55.4209);
//  map = new google.maps.Map(document.getElementById('regularMap'), {
//       zoom: 14,
//       center: myLatlng,
//       scrollwheel: true
//     }); 
//    var polyOptions = {
//     strokeWeight: 0,
//     fillOpacity: 0.45,
//     editable: true,
//     draggable: true
//   };
//   // Creates a drawing manager attached to the map that allows the user to draw
//   // markers, lines, and shapes.
//    drawingManager = new google.maps.drawing.DrawingManager({
//     drawingMode: google.maps.drawing.OverlayType.POLYGON,
//     drawingControlOptions: {
//       drawingModes: [
//         google.maps.drawing.OverlayType.POLYGON
//       ]
//     },
//     markerOptions: {
//       draggable: true
//     },
//     polylineOptions: {
//       editable: true
//     },
//     rectangleOptions: polyOptions,
//     circleOptions: polyOptions,
//     polygonOptions: polyOptions,
//     map: map
//   });


//   google.maps.event.addListener(drawingManager, 'overlaycomplete', function(e) {
//     if (e.type != google.maps.drawing.OverlayType.MARKER) {
//       drawingManager.setDrawingMode(null);
//       drawingManager.setOptions({
//         drawingControl: false
//       });

//       console.log("overlayyyyyyy");
//       console.log(e.overlay);
//       abc = []
//       abc.push(e.overlay.getPaths())
//       map_variable_path = Object.keys(abc[0])[0]
//       console.log(map_variable_path );
      
//       // console.log(e.overlay.getPaths().map_variable_path[0].map_variable_path);
//       // console.log(e.overlay.getPaths().map_variable_path[0].map_variable_path);
//       // console.log(e.overlay);
//       // console.log(e.overlay.getPaths());
//       // console.log(e.overlay.getPaths().j);
//       // console.log(e.overlay.getPaths().j[0]);
//       // console.log(e.overlay.getPaths().j[0].j);


//       // array_lat_lngs(e.overlay.latLngs.b[0].b);
//       // array_lat_lngs(e.overlay.getPaths().j[0].j);
//       array_lat_lngs(e.overlay.getPaths().xd[0].xd);
//       var newShape = e.overlay;
//       newShape.type = e.type;
//       google.maps.event.addListener(newShape, 'click', function() {
//         setSelection(newShape);
//       });
//       setSelection(newShape);
//     }
//   });

//   // Clear the current selection when the drawing mode is changed, or when the
//   // map is clicked.
//   // google.maps.event.addListener(drawingManager, 'drawingmode_changed', clearSelection);
//   // google.maps.event.addListener(map, 'click', clearSelection);
//   // google.maps.event.addDomListener(document.getElementById('delete_selected_zone'), 'click', deleteSelectedShape);
 
//    // array_lat_lngs(drawingManager.getPath().getArray());



// // 
// var ary_lt = [];
//   function array_lat_lngs(ary){
//      ary_lt = [];
//     for (i = 0; i < ary.length; i++) {
//     // console.log(ary);
//     // console.log(ary[i].lat());
//         hsh={};
//         hsh.lat = ary[i].lat();
//         hsh.lng = ary[i].lng();
//         ary_lt.push(hsh);
//         console.log(ary.length);
//         console.log(i);
//         if(i == (ary.length - 1)){
//           console.log("last element");
//           console.log(ary_lt.length);
//           var coordinates = document.getElementById("coordinates");
//           coordinates.value = JSON.stringify(ary_lt);


//         }

//     }
//   }

// function clearSelection() {
//   console.log("clearSelection");
//   if (selectedShape) {
//     console.log(selectedShape);
//     selectedShape.setEditable(false);
//     selectedShape = null;
//   }
// }
// function setSelection(shape) {
//   console.log("setSelection");
//   clearSelection();
//   selectedShape = shape;
//   shape.setEditable(true);
//   addDeleteButton(shape, 'http://i.imgur.com/RUrKV.png');
//   // selectColor(shape.get('fillColor') || shape.get('strokeColor'));
// }

// function addDeleteButton(poly, imageUrl) {
//   var path = poly.getPath();
//   path["btnDeleteClickHandler"] = {};
//   path["btnDeleteImageUrl"] = imageUrl;
  
//   google.maps.event.addListener(poly.getPath(),'set_at',pointUpdated);
//   google.maps.event.addListener(poly.getPath(),'insert_at',pointUpdated);
// }

// function pointUpdated(index) {
//   var path = this;
//   var btnDelete = getDeleteButton(path.btnDeleteImageUrl);
  
//   if(btnDelete.length === 0) 
//   {
//     var undoimg = $("img[src$='https://maps.gstatic.com/mapfiles/undo_poly.png']");
    
//     undoimg.parent().css('height', '21px !important');
//     undoimg.parent().parent().append('<div style="overflow-x: hidden; overflow-y: hidden; position: absolute; width: 30px; height: 27px;top:21px;"><img src="' + path.btnDeleteImageUrl + '" class="deletePoly" style="height:auto; width:auto; position: absolute; left:0;"/></div>');
    
//     // now get that button back again!
//     btnDelete = getDeleteButton(path.btnDeleteImageUrl);
//     btnDelete.hover(function() { $(this).css('left', '-30px'); return false;}, 
//                     function() { $(this).css('left', '0px'); return false;});
//     btnDelete.mousedown(function() { $(this).css('left', '-60px'); return false;});
//   }
  
//   // if we've already attached a handler, remove it
//   if(path.btnDeleteClickHandler) 
//     btnDelete.unbind('click', path.btnDeleteClickHandler);
    
//   // now add a handler for removing the passed in index
//   path.btnDeleteClickHandler = function() {
 
//     path.removeAt(index); 
//     return false;
//   };
//   btnDelete.click(path.btnDeleteClickHandler);
//   console.log("btnDelete");
//   console.log(path);
//   array_lat_lngs(path.g);
//   // console.log(ary_lt);
// }
// function getDeleteButton(imageUrl) {
//   return  $("img[src$='" + imageUrl + "']");
// }



//   var coordinates = document.getElementById("coordinates");
//   console.log("ary_lttttttttttttttttttttt");
//   console.log(ary_lt);
//   coordinates.value = JSON.stringify(ary_lt);


  },
  initBussinessGeofenceGoogleMaps: function() {

    var myLatlng = new google.maps.LatLng(25.3463, 55.4209);
    var mapOptions = {
      zoom: 8,
      center: myLatlng,
      scrollwheel: true, //we disable de scroll over the map, it is a really annoing when you scroll through page
    }
    var map = new google.maps.Map(document.getElementById("regularMap"), mapOptions);

    console.log("json length",json.length);

    // for (var i = 0, length = json.length; i < length; i++) {
    //   data = json[i];
    //   console.log("json data",data.name);      
    //   console.log("json coordinates",data.coordinates[0]);      
    //   var infowindow = new google.maps.InfoWindow();
    //   var geo_area = new google.maps.Polygon({
    //     paths: data.coordinates,
    //     strokeColor: '#FF0000',
    //     strokeOpacity: 0.8,
    //     strokeWeight: 2,
    //     fillColor: '#FF0000',
    //     fillOpacity: 0.35,
    //     geo_name: data.name,
    //     geo_pos: data.coordinates[0]
    //   });

    //   geo_area.setMap(map);
    //   google.maps.event.addListener(geo_area, 'click', function() {
    //     geo_area.infoWindow = new google.maps.InfoWindow();
    //     this.setOptions({fillOpacity:0.1});
    //     geo_area.infoWindow.setPosition(this.geo_pos);
    //     geo_area.infoWindow.setContent(this.geo_name);
    //     geo_area.infoWindow.open(map);
    //   });
    // }


// start new 

console.log("no_parking_geofences_json",no_parking_geofences_json)
console.log("2",parking_discount_geofences_json)
console.log(3,reduced_speed_geofences_json)
console.log(4,ride_available_geofences_json)

    for (var i = 0, length = no_parking_geofences_json.length; i < length; i++) {
      data = no_parking_geofences_json[i];

      var infowindow = new google.maps.InfoWindow();
      var no_parking_geofences_json_geo_area = new google.maps.Polygon({
        paths: data.coordinates,
        strokeColor: '#FF0000',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#ff0000',
        fillOpacity: 0.35,
        geo_name: data.name,
        geo_pos: data.coordinates[0]
      });

      no_parking_geofences_json_geo_area.setMap(map);

    }



    for (var i = 0, length = parking_discount_geofences_json.length; i < length; i++) {
      data = parking_discount_geofences_json[i];
      var infowindow = new google.maps.InfoWindow();
      var parking_discount_geofences_geo_area = new google.maps.Polygon({
        paths: data.coordinates,
        strokeColor: '#FF0000',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#87ceeb',
        fillOpacity: 0.35,
        geo_name: data.name,
        geo_pos: data.coordinates[0]
      });

      parking_discount_geofences_geo_area.setMap(map);

    }



    for (var i = 0, length = reduced_speed_geofences_json.length; i < length; i++) {
      data = reduced_speed_geofences_json[i];
      var infowindow = new google.maps.InfoWindow();
      var reduced_speed_geofences_geo_area = new google.maps.Polygon({
        paths: data.coordinates,
        strokeColor: '#FF0000',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#FFFF00',
        fillOpacity: 0.35,
        geo_name: data.name,
        geo_pos: data.coordinates[0]
      });

      reduced_speed_geofences_geo_area.setMap(map);

    }



    console.log("length",ride_available_geofences_json.length);
    console.log("------------------------------------------")
    console.log("length",ride_available_geofences_json);
     for (var i = 0, length = ride_available_geofences_json.length; i < length; i++) {
      data = ride_available_geofences_json[i];
      console.log("name",data.name);
      console.log("corddd",data.coordinates[0]);
      var infowindow = new google.maps.InfoWindow();
      var ride_available_geofences_geo_area = new google.maps.Polygon({
        paths: data.coordinates,
        strokeColor: '#FF0000',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#a9a9a9',
        fillOpacity: 0.35,
        geo_name: data.name,
        geo_pos: data.coordinates[0]
      });

      ride_available_geofences_geo_area.setMap(map);

    }   
    console.log("=======================================")
    console.log(police_available_geofences_json.length)
    for (var i = 0, length = police_available_geofences_json.length; i < length; i++) {
      console.log("check")
      data = police_available_geofences_json[i];
      console.log("name",data.name);
      console.log("corddd",data.coordinates[0]);
      var infowindow = new google.maps.InfoWindow();
      var police_available_geofences_geo_area = new google.maps.Polygon({
        paths: data.coordinates,
        strokeColor: '#FF0000',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '',
        fillOpacity: 0,
        geo_name: data.name,
        geo_pos: data.coordinates[0]
      });

      police_available_geofences_geo_area.setMap(map);

    }   

    console.log(restricted_geofences_json.length)
    for (var i = 0, length = restricted_geofences_json.length; i < length; i++) {
      console.log("check")
      data = restricted_geofences_json[i];
      console.log("name",data.name);
      console.log("corddd",data.coordinates[0]);
      var infowindow = new google.maps.InfoWindow();
      var restricted_geofences_geo_area = new google.maps.Polygon({
        paths: data.coordinates,
        strokeColor: '#FFA500',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#FFA500',
        fillOpacity: 0.35,
        geo_name: data.name,
        geo_pos: data.coordinates[0]
      });

      restricted_geofences_geo_area.setMap(map);

    }   


// start new ends

var selectedShape;
    var myLatlng = new google.maps.LatLng(25.3463, 55.4209);
//  map = new google.maps.Map(document.getElementById('regularMap'), {
//       zoom: 14,
//       center: myLatlng,
//       scrollwheel: true
//     }); 
   var polyOptions = {
    strokeWeight: 0,
    fillOpacity: 0.45,
    editable: true,
    draggable: true
  };
//   // Creates a drawing manager attached to the map that allows the user to draw
//   // markers, lines, and shapes.
   drawingManager = new google.maps.drawing.DrawingManager({
    drawingMode: google.maps.drawing.OverlayType.POLYGON,
    drawingControlOptions: {
      drawingModes: [
        google.maps.drawing.OverlayType.POLYGON
      ]
    },
    markerOptions: {
      draggable: true
    },
    polylineOptions: {
      editable: true
    },
    rectangleOptions: polyOptions,
    circleOptions: polyOptions,
    polygonOptions: polyOptions,
    map: map
  });


  google.maps.event.addListener(drawingManager, 'overlaycomplete', function(e) {
    if (e.type != google.maps.drawing.OverlayType.MARKER) {
      drawingManager.setDrawingMode(null);
      drawingManager.setOptions({
        drawingControl: false
      });

      console.log("overlayyyyyyy");
      console.log(e.overlay);
      abc = []
      abc.push(e.overlay.getPaths())
      map_variable_path = Object.keys(abc[0])[0]
      console.log(map_variable_path );
      


      array_lat_lngs(e.overlay.getPaths().Eg[0].Eg);
      var newShape = e.overlay;
      newShape.type = e.type;
      google.maps.event.addListener(newShape, 'click', function() {
        setSelection(newShape);
      });
      setSelection(newShape);
    }
  });

//   // Clear the current selection when the drawing mode is changed, or when the
//   // map is clicked.
//   // google.maps.event.addListener(drawingManager, 'drawingmode_changed', clearSelection);
//   // google.maps.event.addListener(map, 'click', clearSelection);
//   // google.maps.event.addDomListener(document.getElementById('delete_selected_zone'), 'click', deleteSelectedShape);
 
//    // array_lat_lngs(drawingManager.getPath().getArray());



// // 
var ary_lt = [];
  function array_lat_lngs(ary){
     ary_lt = [];
    for (i = 0; i < ary.length; i++) {
    // console.log(ary);
    // console.log(ary[i].lat());
        hsh={};
        hsh.lat = ary[i].lat();
        hsh.lng = ary[i].lng();
        ary_lt.push(hsh);
        console.log(ary.length);
        console.log(i);
        if(i == (ary.length - 1)){
          console.log("last element");
          console.log(ary_lt.length);
          var coordinates = document.getElementById("coordinates");
          coordinates.value = JSON.stringify(ary_lt);


        }

    }
  }

function clearSelection() {
  console.log("clearSelection");
  if (selectedShape) {
    console.log(selectedShape);
    selectedShape.setEditable(false);
    selectedShape = null;
  }
}
function setSelection(shape) {
  console.log("setSelection");
  clearSelection();
  selectedShape = shape;
  shape.setEditable(true);
  addDeleteButton(shape, 'http://i.imgur.com/RUrKV.png');
  // selectColor(shape.get('fillColor') || shape.get('strokeColor'));
}

function addDeleteButton(poly, imageUrl) {
  var path = poly.getPath();
  path["btnDeleteClickHandler"] = {};
  path["btnDeleteImageUrl"] = imageUrl;
  
  google.maps.event.addListener(poly.getPath(),'set_at',pointUpdated);
  google.maps.event.addListener(poly.getPath(),'insert_at',pointUpdated);
}

function pointUpdated(index) {
  var path = this;
  var btnDelete = getDeleteButton(path.btnDeleteImageUrl);
  
  if(btnDelete.length === 0) 
  {
    var undoimg = $("img[src$='https://maps.gstatic.com/mapfiles/undo_poly.png']");
    
    undoimg.parent().css('height', '21px !important');
    undoimg.parent().parent().append('<div style="overflow-x: hidden; overflow-y: hidden; position: absolute; width: 30px; height: 27px;top:21px;"><img src="' + path.btnDeleteImageUrl + '" class="deletePoly" style="height:auto; width:auto; position: absolute; left:0;"/></div>');
    
    // now get that button back again!
    btnDelete = getDeleteButton(path.btnDeleteImageUrl);
    btnDelete.hover(function() { $(this).css('left', '-30px'); return false;}, 
                    function() { $(this).css('left', '0px'); return false;});
    btnDelete.mousedown(function() { $(this).css('left', '-60px'); return false;});
  }
  
  // if we've already attached a handler, remove it
  if(path.btnDeleteClickHandler) 
    btnDelete.unbind('click', path.btnDeleteClickHandler);
    
  // now add a handler for removing the passed in index
  path.btnDeleteClickHandler = function() {
 
    path.removeAt(index); 
    return false;
  };
  btnDelete.click(path.btnDeleteClickHandler);
  array_lat_lngs(path.g);
  // console.log(ary_lt);
}
function getDeleteButton(imageUrl) {
  return  $("img[src$='" + imageUrl + "']");
}



  var coordinates = document.getElementById("coordinates");
  coordinates.value = JSON.stringify(ary_lt);



// ++++++
  },



  createPolygon_old: function() {
    console.log("njbs");

    var myLatlng = new google.maps.LatLng(25.3463, 55.4209);
    var mapOptions = {
      zoom: 8,
      center: myLatlng,
      scrollwheel: false, //we disable de scroll over the map, it is a really annoing when you scroll through page
    }

    var map = new google.maps.Map(document.getElementById("regularMap"), mapOptions);

    var polyOptions = {
      strokeColor: 'red',
      strokeOpacity: 1.0,
      strokeWeight: 3
    };

    for (var i = 0, length = json.length; i < length; i++) {
      data = json[i];
      var infowindow = new google.maps.InfoWindow();
      var geo_area = new google.maps.Polygon({
        paths: data.coordinates,
        strokeColor: '#FF0000',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#FF0000',
        fillOpacity: 0.35,
        geo_name: data.name,
        geo_pos: data.coordinates[0]
      });

      geo_area.setMap(map);
    }
    poly = new google.maps.Polygon(polyOptions);
    poly.setMap(map);
    google.maps.event.addListener(map, 'click', addLatLng);

    function addLatLng(event){
      path = poly.getPath();
      path.push(event.latLng);
      var coordinates = document.getElementById("coordinates");
      coordinates.value = JSON.stringify(path);
    }

    var input = document.getElementById( 'undo' );
    if (input != null) {
      input.addEventListener( 'click', removeLine );
    }

    function removeLine() {
      var path_line = path.pop();
      if (path_line) path_line.setMap(null);
    }
  },

  
  initGeofencePlanGoogleMaps: function() {
    console.log("78789789789")
    var myLatlng = new google.maps.LatLng(25.3463, 55.4209);
    var mapOptions = {
      zoom: 8,
      center: myLatlng,
      scrollwheel: true, //we disable de scroll over the map, it is a really annoing when you scroll through page
    }
    var map = new google.maps.Map(document.getElementById("regularMap"), mapOptions);

    console.log("json length",json.length);


    // start new 

      console.log(json.length)
      for (var i = 0, length = json.length; i < length; i++) {
        data = json[i];

        var infowindow = new google.maps.InfoWindow();
        var json_geo_area = new google.maps.Polygon({
          paths: data.coordinates,
          strokeColor: '#FF0000',
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: '#ff0000',
          fillOpacity: 0.35,
          geo_name: data.name,
          geo_pos: data.coordinates[0]
        });

        json_geo_area.setMap(map);

      }

    // start new ends

    var selectedShape;
        var myLatlng = new google.maps.LatLng(25.3463, 55.4209);
    //  map = new google.maps.Map(document.getElementById('regularMap'), {
    //       zoom: 14,
    //       center: myLatlng,
    //       scrollwheel: true
    //     }); 
      var polyOptions = {
        strokeWeight: 0,
        fillOpacity: 0.45,
        editable: true,
        draggable: true
      };
    //   // Creates a drawing manager attached to the map that allows the user to draw
    //   // markers, lines, and shapes.
    drawingManager = new google.maps.drawing.DrawingManager({
      drawingMode: google.maps.drawing.OverlayType.POLYGON,
      drawingControlOptions: {
        drawingModes: [
          google.maps.drawing.OverlayType.POLYGON
        ]
      },
      markerOptions: {
        draggable: true
      },
      polylineOptions: {
        editable: true
      },
      rectangleOptions: polyOptions,
      circleOptions: polyOptions,
      polygonOptions: polyOptions,
      map: map
    });


    google.maps.event.addListener(drawingManager, 'overlaycomplete', function(e) {
      if (e.type != google.maps.drawing.OverlayType.MARKER) {
        drawingManager.setDrawingMode(null);
        drawingManager.setOptions({
          drawingControl: false
        });

        console.log("overlayyyyyyy");
        console.log(e.overlay);
        abc = []
        abc.push(e.overlay.getPaths())
        map_variable_path = Object.keys(abc[0])[0]
        console.log(map_variable_path );
        

        array_lat_lngs(e.overlay.getPaths().Eg[0].Eg);
        var newShape = e.overlay;
        newShape.type = e.type;
        google.maps.event.addListener(newShape, 'click', function() {
          setSelection(newShape);
        });
        setSelection(newShape);
      }
    });

      // Clear the current selection when the drawing mode is changed, or when the
      // map is clicked.
      // google.maps.event.addListener(drawingManager, 'drawingmode_changed', clearSelection);
      // google.maps.event.addListener(map, 'click', clearSelection);
      // google.maps.event.addDomListener(document.getElementById('delete_selected_zone'), 'click', deleteSelectedShape);
    
       // array_lat_lngs(drawingManager.getPath().getArray());



    // 
    var ary_lt = [];
    function array_lat_lngs(ary){
      ary_lt = [];
      for (i = 0; i < ary.length; i++) {
      // console.log(ary);
      // console.log(ary[i].lat());
          hsh={};
          hsh.lat = ary[i].lat();
          hsh.lng = ary[i].lng();
          ary_lt.push(hsh);
          console.log(ary.length);
          console.log(i);
          if(i == (ary.length - 1)){
            console.log("last element");
            console.log(ary_lt.length);
            var coordinates = document.getElementById("coordinates");
            coordinates.value = JSON.stringify(ary_lt);


          }

      }
    }

    function clearSelection() {
      console.log("clearSelection");
      if (selectedShape) {
        console.log(selectedShape);
        selectedShape.setEditable(false);
        selectedShape = null;
      }
    }
    function setSelection(shape) {
      console.log("setSelection");
      clearSelection();
      selectedShape = shape;
      shape.setEditable(true);
      addDeleteButton(shape, 'http://i.imgur.com/RUrKV.png');
      // selectColor(shape.get('fillColor') || shape.get('strokeColor'));
    }

    function addDeleteButton(poly, imageUrl) {
      var path = poly.getPath();
      path["btnDeleteClickHandler"] = {};
      path["btnDeleteImageUrl"] = imageUrl;
      
      google.maps.event.addListener(poly.getPath(),'set_at',pointUpdated);
      google.maps.event.addListener(poly.getPath(),'insert_at',pointUpdated);
    }

    function pointUpdated(index) {
      var path = this;
      var btnDelete = getDeleteButton(path.btnDeleteImageUrl);
      
      if(btnDelete.length === 0) 
      {
        var undoimg = $("img[src$='https://maps.gstatic.com/mapfiles/undo_poly.png']");
        
        undoimg.parent().css('height', '21px !important');
        undoimg.parent().parent().append('<div style="overflow-x: hidden; overflow-y: hidden; position: absolute; width: 30px; height: 27px;top:21px;"><img src="' + path.btnDeleteImageUrl + '" class="deletePoly" style="height:auto; width:auto; position: absolute; left:0;"/></div>');
        
        // now get that button back again!
        btnDelete = getDeleteButton(path.btnDeleteImageUrl);
        btnDelete.hover(function() { $(this).css('left', '-30px'); return false;}, 
                        function() { $(this).css('left', '0px'); return false;});
        btnDelete.mousedown(function() { $(this).css('left', '-60px'); return false;});
      }
      
      // if we've already attached a handler, remove it
      if(path.btnDeleteClickHandler) 
        btnDelete.unbind('click', path.btnDeleteClickHandler);
        
      // now add a handler for removing the passed in index
      path.btnDeleteClickHandler = function() {
    
        path.removeAt(index); 
        return false;
      };
      btnDelete.click(path.btnDeleteClickHandler);
      array_lat_lngs(path.g);
      // console.log(ary_lt);
    }
    function getDeleteButton(imageUrl) {
      return  $("img[src$='" + imageUrl + "']");
    }



    var coordinates = document.getElementById("coordinates");
   
    coordinates.value = JSON.stringify(ary_lt);


    // ++++++
  }

}